import { ListItemIcon } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Star } from "@material-ui/icons";
import React from "react";
import styled from 'styled-components';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: "100%",
      // backgroundColor: '#0009',
    },
    inline: {
      display: "inline",
    },
  })
);

const mainList = [
  {
    title: "CAPRI",
    imageSrc: "../../assets/skills/test.svg",
    subList: [
      "Working on a module which includes backend service apis build in nest.js with typescript framework and React on client side using postgresql as sql database.",
      "Made things easy with creating component library and using styled components in React Web Application.",
    ],
  },
 
];

const TitleSpan = styled.span`
    color: #c7abb7;
    font-weight: bold;
    position: relative;
    top: 7px;
    font-size: 22px;
`

export const ListOfItemsForASTS = () => {
  const classes = useStyles();

  return (
    <List className={classes.root}>
     { mainList.map(x=>{
         return (<><ListItem alignItems="flex-start">
         <ListItemAvatar>
           <Avatar
             alt={x.title}
             src={x.imageSrc}
           />
         </ListItemAvatar>
         <ListItemText
           primary={<TitleSpan>{x.title}</TitleSpan>}
           secondary={
             <React.Fragment>
               <List
                 component="nav"
                 className={classes.root}
                 aria-label="contacts"
               >
                {x.subList.map(item=>{
                    return (
<ListItem>
                   <ListItemIcon>
                     <Star style={{color:'#61434ffc'}}/>
                   </ListItemIcon>
                   <ListItemText primary={item} />
                 </ListItem>
                    )
                }) }
               
               </List>
             </React.Fragment>
           }
         />
       </ListItem>
       <Divider variant="inset" component="li" /></>)
     }) }
    </List>
  );
};
