import Rating from "@material-ui/lab/Rating";
import React from "react";
import styled from "styled-components";
import angular from "../../assets/skills/angular.svg";
import apple from "../../assets/skills/apple.svg";
import aws from "../../assets/skills/aws.svg";
import blockchain from "../../assets/skills/blockchain.svg";
import docker from "../../assets/skills/docker.svg";
import Git from "../../assets/skills/git.svg";
import github from "../../assets/skills/github.svg";
import html from "../../assets/skills/html.svg";
import javascript from "../../assets/skills/javascript.svg";
import jira from "../../assets/skills/jira.svg";
import mongo from "../../assets/skills/mongo.svg";
import nest from "../../assets/skills/nest1.svg";
import nodejs from "../../assets/skills/node-js.svg";
import postgres from "../../assets/skills/postgres.png";
import python from "../../assets/skills/python.svg";
import react from "../../assets/skills/react.svg";
import redux from "../../assets/skills/redux.svg";
import sequelize from "../../assets/skills/sequelize.svg";
import typescript from "../../assets/skills/typescript.svg";
import netlify from "../../assets/skills/netlify.svg";
import ubuntu from "../../assets/skills/ubuntu.svg";
import vscode from "../../assets/skills/vscode.svg";
import win from "../../assets/skills/win.svg";
import { Footer } from "../layouts/footer/footer";


const CustomSpan = styled.span`
  display: inline-flex;
  align-items: center;
`;

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 29%;
  @media screen and (max-width: 800px) {
    width: 60%;
  }
  flex-direction: row;
  justify-content: space-around;
`;

const CustomImg = styled.img`
  height: 54px;
  @media screen and (max-width: 800px) {
    height: 40px;
  }
`;

const MainDiv = styled.div`
  font-size: 31px;
  display: flex;
  font-weight: bolder;
  color: var(--color-normal-text);
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 50px;
  @media screen and (max-width: 800px) {
    font-size: 23px;
  }
`;

const data = [
  { imagePath: nodejs, name: "Nodejs", rating: 8 },
  { imagePath: nest, name: "Nest", rating: 7 },
  { imagePath: javascript, name: "Javascript", rating: 8 },
  { imagePath: react, name: "React", rating: 7 },
  { imagePath: redux, name: "Redux", rating: 5 },
  { imagePath: html, name: "Html", rating: 7 },
  { imagePath: typescript, name: "Typescript", rating: 7 },
  { imagePath: angular, name: "Angular", rating: 5 },
  {
    imagePath: Git,
    name: "Git",
    rating: 8,
  },
  { imagePath: github, name: "Github", rating: 6 },
  { imagePath: jira, name: "Jira", rating: 6.5 },
  { imagePath: sequelize, name: "Sequelize", rating: 7 },
  { imagePath: postgres, name: "Postgres", rating: 7 },
  { imagePath: mongo, name: "Mongo", rating: 6 },
  { imagePath: python, name: "Python", rating: 4 },
  { imagePath: aws, name: "Aws", rating: 4 },
  { imagePath: netlify, name: "Netlify", rating: 6 },
  { imagePath: blockchain, name: "Blockchain", rating: 5 },
  { imagePath: docker, name: "Docker", rating: 4 },
  { imagePath: vscode, name: "Visualstudio", rating: 7 },
  
];

const os = [{ imagePath: apple, name: "Apple", rating: 6 },
{ imagePath: win, name: "Win", rating: 6 },
{ imagePath: ubuntu, name: "Ubuntu", rating: 8 },]

const MainDiv1 = styled.div`
height: 100%;
margin-bottom: 54px;
padding: 25px;
overflow: hidden overlay;
@media screen and (max-width: 800px) {
  padding:5px;
  padding-right: 15px !important;
}
`

export const Skills = () => {
  return (
    <>
      <MainDiv1>
        {/* <div className="some-area fill-height-or-more">
  <div>
      <h1>Boxes That Fill Height (or more)</h1>
      <DivFlexColumn>
      <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
        </DivFlexColumn>
     
  </div>
</div> */}

        {data.map((x) => {
          return (
            <MainDiv>
              <HeaderDiv>
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                  {" "}
                  <CustomImg src={x.imagePath} />
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    width: "126px",
                    textAlign: "left",
                  }}
                >
                  <CustomSpan>{x.name}</CustomSpan>
                </div>
              </HeaderDiv>
              <div style={{ display: "inline-flex", alignItems: "center" }}>
                {" "}
                <Rating
                  name="half-rating-read"
                  defaultValue={x.rating}
                  precision={0.5}
                  max={10}
                  readOnly
                />
              </div>
            </MainDiv>
          );
        })}
       
<div style={{color: '#8169b3f0',fontSize: '31px',margin: '24px', textAlign:'center'}}>Comfortable with below mentioned OS</div>
{os.map((x) => {
          return (
            <MainDiv>
              <HeaderDiv>
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                  {" "}
                  <CustomImg src={x.imagePath} />
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    width: "126px",
                    textAlign: "left",
                  }}
                >
                  <CustomSpan>{x.name}</CustomSpan>
                </div>
              </HeaderDiv>
              <div style={{ display: "inline-flex", alignItems: "center" }}>
                {" "}
                <Rating
                  name="half-rating-read"
                  defaultValue={x.rating}
                  precision={0.5}
                  max={10}
                  readOnly
                />
              </div>
            </MainDiv>
          );
        })}
        <Footer />
      </MainDiv1>
    </>
  );
};
