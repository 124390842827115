import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  html {
    --color-text: linear-gradient(90deg,rgba(158, 243, 255, 1) 0%,rgba(255, 191, 214, 1) 53%,rgba(158, 243, 255, 1) 100%);
    --color-text-2:linear-gradient(90deg, #a478ff 0%, #e8b9ca 53%, #c64fce 100%);
    --color-text-3:linear-gradient(90deg,#f4c6ff 0%,#ffbfd6 53%,#cb809b 100%);
    --color-background: white;
    --color-primary: rebeccapurple;
    --card-bg-color: linear-gradient(90deg,rgb(158 243 255 / 21%) 0%,rgb(255 191 214 / 21%) 53%,rgb(158 243 255 / 21%) 100%);
    --color-purple-icon: #1a0b3c ;
    --color-timeline: #5240a5;
    --color-normal-text: #cfcfcfe3;
    --color-normal-text-2: #c1c1c1b5;
  }
`;
