import { ListItemIcon } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Star } from "@material-ui/icons";
import React from "react";
import styled from 'styled-components';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: "100%",
      // backgroundColor: '#0009',
    },
    inline: {
      display: "inline",
    },
  })
);


const mainList = [
  {
    title: "Recommendation Web-app in banking sector",
    imageSrc: "../../assets/skills/test.svg",
    subList: [
      "Developed NodeJs APIs and created modules, services and components for Angular 4 web application.",
      "Increased the visualization and analysis power of business performance by 3X using jsreports.",
      "Used torodb – a real-time data replication service to synchronize data from NoSQL to SQL database.",
    ],
  },
  {
    title: "Cognitive Receipt Recognition Engine",
    imageSrc: "../../assets/skills/test.svg",
    subList: [
      "Single-handedly designed and developed a web application using HTML, bootstrap and flask as a python microframework.",
      "Facilitating the recognition performance and fasten the speed by 50% using multiprocessing.",
      "Facilitated this application as debian app for easy usage for linux OS.",
    ],
  },
  {
    title: "Securing identity information of a user with Blockchain",
    imageSrc: "../../assets/skills/test.svg",
    subList: [
      "Worked on monetising and secure sharing of personal identification information.",
      "Performed feasibility analysis of backend and Ethereum blockchain development.",
      "Encryption and safekeeping of KYC data using state of art RSA encryption algorithms.",
    ],
  },
];

const TitleSpan = styled.span`
    color: #95abbe;
    font-weight: bold;
    position: relative;
    top: 7px;
    font-size: 22px;
`

export const ListOfItemsForLumiq = () => {
  const classes = useStyles();

  return (
    <List className={classes.root}>
     { mainList.map(x=>{
         return (<><ListItem alignItems="flex-start">
         <ListItemAvatar>
           <Avatar
             alt={x.title}
             src={x.imageSrc}
           />
         </ListItemAvatar>
         <ListItemText
           primary={<TitleSpan>{x.title}</TitleSpan>}
           secondary={
             <React.Fragment>
               <List
                 component="nav"
                 className={classes.root}
                 aria-label="contacts"
               >
                {x.subList.map(item=>{
                    return (
<ListItem>
                   <ListItemIcon>
                     <Star style={{color:'#163854'}}/>
                   </ListItemIcon>
                   <ListItemText primary={item} />
                 </ListItem>
                    )
                }) }
               
               </List>
             </React.Fragment>
           }
         />
       </ListItem>
       <Divider variant="inset" component="li" /></>)
     }) }
    </List>
  );
};
