import { LocationCity, SchoolSharp, Work } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import styled from "styled-components";
import IITR from '../../assets/iitr.jpg';
import LumiqImage from '../../assets/lumiq_group.jpg';
import { Footer } from "../layouts/footer/footer";

export const About = () => {
  


  const CustomImg  = styled.img`
  margin-top: 40px;
    margin-bottom: 34px;
    width: 100%;
    border-radius: 9px;
  `
const CustomContentSpan = styled.span`
line-height: 1.6;
color: var(--color-normal-text-2);
`
const MainDiv = styled.div`
height: 100%;
margin-bottom: 54px;
padding: 25px;
overflow: hidden overlay;
@media screen and (max-width: 800px) {
  padding:5px;
  padding-right: 15px !important;
}
`

  return (
    <>
    
        <MainDiv>
        <div className={'aboutus__header'}> 
        
        <CustomContentSpan>I am <span className={'gradientText3'}>Rajat</span>, a <span className={'gradientText3'}>Full Stack Engineer</span> working for <a
            href="https://edfibre.com/"
            target="_blank"
            className="no_decor"
            rel="noopener noreferrer"
          ><span className={'gradientText3 show_decor'}>ASTS</span></a> which is an Australian based Ed-Tech company.</CustomContentSpan>
          </div>
          <br/>
          <div className={'aboutus__subheader'}>
          <CustomContentSpan>
          I’ve spent the past 3+ years working across different areas of front-end development, back-end development, blockchain to my current role of developing products for web platforms.
          </CustomContentSpan>
          <CustomContentSpan> I keep myself busy in occasional freelancing, <Link to={'/profile/projects'} className="no_decor"><span className={'gradientText3 show_decor'}>personal projects</span></Link> ( to either tackle my problems or of the society ) and music.</CustomContentSpan>
        </div>
         <VerticalTimeline>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    date="2020 - Present"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<Work />}
  >
    <h3 className="vertical-timeline-element-title pink_text">Full Stack Engineer</h3>
    <h4 className="vertical-timeline-element-subtitle pink_text">ASTS, Australia (Remote)</h4>
    <div>
    <CustomContentSpan>
      Explore more: <Link to={'/profile/work'} className="no_decor"><span style={{
        color: '#ffffff',
        textDecoration: 'underline'
      }}>Checkout to Work Section</span></Link>
  </CustomContentSpan></div>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="2018 - 2020"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<Work />}
  >
    <h3 className="vertical-timeline-element-title pink_text">Software Engineer</h3>
    <h4 className="vertical-timeline-element-subtitle pink_text">LUMIQ, India</h4>
    <div>
      <CustomImg src={LumiqImage} />
      <CustomContentSpan>
      Explore more: <Link to={'/profile/work'} className="no_decor"><span style={{
        color: '#ffffff',
        textDecoration: 'underline'
      }}>Checkout to Work Section</span></Link>
  </CustomContentSpan></div>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="2014 - 2018"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<SchoolSharp />}
  >
    <h3 className="vertical-timeline-element-title pink_text">Student, Bachelor of Technology</h3>
    <h4 className="vertical-timeline-element-subtitle pink_text">IIT Roorkee, India</h4>
    <h4 className="vertical-timeline-element-subtitle2 gold_color">Scored: 7.67/10</h4>
    
    <div>
    <CustomImg src={IITR} />
    <CustomContentSpan>
    Pursued bachelor's degree in Material Science.
  </CustomContentSpan></div>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="2012 - 2013"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<LocationCity />}
  >
    <h3 className="vertical-timeline-element-title pink_text">Student, Secondary School</h3>
    <h4 className="vertical-timeline-element-subtitle pink_text">DPS Aligarh (CBSE), India</h4>
    <h4 className="vertical-timeline-element-subtitle2 gold_color">Scored: 92.6%</h4>
    
    <div>
    <CustomContentSpan>
      It was the turning point when I felt that I actually achieved something, scoring <CustomContentSpan style={{color:'#e2b6cf', fontWeight:'bold'}}>100 marks in Mathematics</CustomContentSpan> and 96 marks in C++, where I got some confidence in programming.
  </CustomContentSpan></div>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="2010-2011"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={<LocationCity />}
  >
    <h3 className="vertical-timeline-element-title pink_text">Student, High School</h3>
    <h4 className="vertical-timeline-element-subtitle pink_text">TDSSS Aligarh (ICSE), India</h4>
    <h4 className="vertical-timeline-element-subtitle2 gold_color">Scored: 81.57%</h4>
    
    <div>
    <CustomContentSpan>
     When for the first time I was introduced to the world of programming with Java as my optional subject, at that time it was a disaster 🤯.
  </CustomContentSpan>
  </div>
  </VerticalTimelineElement>

  {/* <VerticalTimelineElement
    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
    icon={<Star />}
  /> */}
</VerticalTimeline> 
<Footer />
        </MainDiv>
       
    </>
  );
};
