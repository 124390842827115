import { Card, CardContent, CardHeader, IconButton } from "@material-ui/core";
import React from "react";
import { FaBars } from "react-icons/fa";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import AboutIcon from "../../assets/creative.svg";
import ProjectIcon from "../../assets/project1.svg";
import SkillsIcon from "../../assets/skills.svg";
import WorkIcon from "../../assets/suitcase.svg";
import { About } from "../pages/about";
import { Contact } from "../pages/contact";
import { Projects } from "../pages/projects";
import { Skills } from "../pages/skills";
import { Work } from "../pages/work";
import { MainFooter } from "./footer/mainFooter";

interface MainLayoutProps {
  type: string;
  handleToggleSidebar: any;
}

// const useStyles = makeStyles((theme) => ({
//     root: {
//       maxWidth: 345,
//     },
//     media: {
//       height: 0,
//       paddingTop: '56.25%', // 16:9
//     },
//     expand: {
//       transform: 'rotate(0deg)',
//       marginLeft: 'auto',
//       transition: theme.transitions.create('transform', {
//         duration: theme.transitions.duration.shortest,
//       }),
//     },
//     expandOpen: {
//       transform: 'rotate(180deg)',
//     },
//     avatar: {
//       backgroundColor: red[500],
//     },
//   }));

// Use media Query
// const is3Cols = useMediaQuery({ minWidth: 1440 });
//   const is2Cols = useMediaQuery({ minWidth: 1265 });
//   const is1Cols = useMediaQuery({ minWidth: 800 });

export const MainLayout: React.FC<MainLayoutProps> = ({
  type,
  handleToggleSidebar,
}) => {
  // const classes = useStyles();

  const location = useLocation();
  const pathName = location.pathname;

  if (type === undefined) {
    type = "academics";
  }

  const MainDiv = styled.div`
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    position: relative;
  `;
  // background: #00000070;
  const MainCard = styled(Card)`
    color: var(--color-text) !important;
    width: 91%;
    height: 92%;
    border: 5px solid #10101080 !important;
    box-shadow: 0 20px 20px #0000006e, 0px 0px 50px #0505054f !important;
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 1) 0%,
      rgba(2, 2, 2, 0.28335084033613445) 0%,
      #0202025e 27%,
      #020202d6 76%,
      rgba(4, 4, 4, 1) 100%
    );
    border-radius: 40px !important;
    background-color: transparent !important;
    justify-content: center;
    background-repeat: round;
    align-items: center;
    @media screen and (max-width: 800px) {
      width: 100%;
      height: 100%;
    }
  `;

  const CustomImg = styled.img`
  height: 67px;
  @media screen and (max-width: 800px) {
    height: 45px;
  }
  `

  const headerIconSize = '67px'
  let { path } = useRouteMatch();

  function CustomHeaderIcon() {
    if (pathName.replace("/", "") === "profile") {
      return (
        <div className="icon">
         <CustomImg src={WorkIcon} alt={"Work"} />
        </div>
      );
    } else if (pathName === "/profile/work") {
      return (
        <div className="icon">
         <CustomImg src={WorkIcon} alt={"Work"} />
        </div>
      );
    } else if (pathName === "/profile/aboutme") {
      return (
        <div className="icon">
         <CustomImg src={AboutIcon} alt={"About"} />
        </div>
      );
    }
    else if (pathName === "/profile/projects") {
      return (
        <div className="icon">
         <CustomImg src={ProjectIcon} alt={"About"} />
        </div>
      );
    }
    else if (pathName === "/profile/skills") {
      return (
        <div className="icon">
         <CustomImg src={SkillsIcon} alt={"About"} />
        </div>
      );
    }
    return (
      <div className="icon">
        <img style={{ height: headerIconSize }} src={AboutIcon} alt={"About"} />
      </div>
    );
  }

  function CustomHeaderTitle() {
    if (pathName.replace("/", "") === "profile") {
      return <>Profile</>;
    } else if (pathName === "/profile/work") {
      return <>Work</>;
    } else if (pathName === "/profile/aboutme") {
      return <>About Me</>;
    }
    else if (pathName === "/profile/projects") {
      return <>Projects/Miscellaneous</>;
    }
    else if (pathName === "/profile/skills") {
      return <>Skills</>;
    }
    else if (pathName === "/profile/contact") {
      return <>Contact</>;
    }
    return <>Please select option from side bar</>;
  }

  

  return (
    <>
      <MainDiv>
        <div
          className="btn-toggle"
          style={{ position: "fixed", top: 12, left: 12 }}
          onClick={() => handleToggleSidebar(true)}
        >
          <FaBars />
        </div>
        <MainCard>
          <CardHeader
            avatar={
              <span>
                <CustomHeaderIcon />
              </span>
            }
            action={<IconButton aria-label="settings"></IconButton>}
            title={
              <span className="cardHeaderTitle">
                <CustomHeaderTitle />
              </span>
            }
          />
          <CardContent style={{height:'90%'}}>
            <Switch>
              <Route path={`${path}`} exact={true}>
                Home
              </Route>
              <Route path={`${path}/work`} exact={true}>
                <Work />
              </Route>
              <Route path={`${path}/aboutme`} exact={true}>
                <About />
              </Route>
              <Route path={`${path}/projects`} exact={true}>
                <Projects />
              </Route>
              <Route path={`${path}/skills`} exact={true}>
                <Skills />
              </Route>
              <Route path={`${path}/contact`} exact={true}>
                <Contact />
              </Route>
            </Switch>
          </CardContent>
        </MainCard>
      </MainDiv>
      <MainFooter/>
    </>
  );
};
