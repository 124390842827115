import React from "react";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader
} from "react-pro-sidebar";
import { useMediaQuery } from "react-responsive";
import { Link, NavLink } from "react-router-dom";
import ToggleButton from 'react-switch';
import styled from "styled-components";
import CommunicateIcon from "../../../assets/communicate.svg";
import AboutIcon from "../../../assets/creative.svg";
import owner from "../../../assets/owner_1.png";
import ProjectIcon from "../../../assets/project1.svg";
import SkillsIcon from "../../../assets/skills.svg";
// import logo from "../../../assets/logo.png";
import WorkIcon from "../../../assets/suitcase.svg";


const CollapseDiv = styled.div`
position: relative;
right: 0px;
margin-top: 14px;
/* top: 7px; */
margin-bottom: 12px;
z-index: 1009;
`

const Aside = ({ collapsed, toggled, handleToggleSidebar,handleCollapsedChange  }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
 // const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  //const intl = useIntl();
  return (
    <ProSidebar
      // image={image ? sidebarBg : false}

      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
            letterSpacing: "1px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textAlign: "center",
            top: '13px',
    position: 'relative'
          }}
        >
          <Link to={"/"}>
            <img
              alt="logo"
              src={owner}
              style={{
                width: "100%",
                borderRadius:'50%',
                backgroundColor: '#0000001a',
                marginBottom: '13px',
                height: "auto",
                maxWidth: isBigScreen ? "120px" : (isDesktopOrLaptop ? '100px' : '80px'),
                transition: "ease 0.3s",
              }}
            />{" "}
            <br />
          </Link>

          {!collapsed && (
            <span style={{ transition: "ease 0.3s", color: '#faddd5',fontSize: '16px' }}>RAJAT SEHGAL</span>
          )}
        </div>
      </SidebarHeader>

      <SidebarContent>
        {/* <Menu iconShape="circle">
          <MenuItem
            icon={<FaTachometerAlt />}
            suffix={<span className="badge red">{intl.formatMessage({ id: 'new' })}</span>}
          >
            {intl.formatMessage({ id: 'dashboard' })}
          </MenuItem>
          <MenuItem icon={<FaGem />}> {intl.formatMessage({ id: 'components' })}</MenuItem>
        </Menu> */}
        <Menu iconShape="">

        <NavLink  activeStyle={{textDecoration:'underline #cfa5ff'}} to={"/profile/aboutme"}> <MenuItem  icon={<div className="icon">
            <img  style={{height:'50px'}} src={AboutIcon} alt={'about'} />
            </div>}>
            {" "}
           About </MenuItem></NavLink>

           
        <NavLink  activeStyle={{textDecoration:'underline #cfa5ff'}} to={"/profile/work"}> <MenuItem icon={ <div className="icon">
        <img style={{height:'50px'}} src={WorkIcon} alt={'Work'} />
            </div>}>  Work</MenuItem>
           </NavLink>
         
           
           <NavLink  activeStyle={{textDecoration:'underline #cfa5ff'}} to={"/profile/projects"}> <MenuItem  icon={<div className="icon">
            <img  style={{height:'50px'}} src={ProjectIcon} alt={'project'} />
            </div>}>
            {" "}
           Projects </MenuItem></NavLink>
           <NavLink  activeStyle={{textDecoration:'underline #cfa5ff'}} to={"/profile/skills"}> <MenuItem  icon={<div className="icon">
            <img  style={{height:'50px'}} src={SkillsIcon} alt={'skills'} />
            </div>}>
            {" "}
           Skills </MenuItem></NavLink>
         
          {/* <SubMenu
            suffix={<span className="badge yellow">3</span>}
            title={intl.formatMessage({ id: "withSuffix" })}
            icon={<FaRegLaughWink />}
          >
            <MenuItem>{intl.formatMessage({ id: "submenu" })} 1</MenuItem>
            <MenuItem>{intl.formatMessage({ id: "submenu" })} 2</MenuItem>
            <MenuItem>{intl.formatMessage({ id: "submenu" })} 3</MenuItem>
          </SubMenu> */}
          {/* <SubMenu
            prefix={<span className="badge gray">3</span>}
            title={intl.formatMessage({ id: 'withPrefix' })}
            icon={<FaHeart />}
          >
            <MenuItem>{intl.formatMessage({ id: 'submenu' })} 1</MenuItem>
            <MenuItem>{intl.formatMessage({ id: 'submenu' })} 2</MenuItem>
            <MenuItem>{intl.formatMessage({ id: 'submenu' })} 3</MenuItem>
          </SubMenu> */}
          {/* <SubMenu title={intl.formatMessage({ id: 'multiLevel' })} icon={<FaList />}>
            <MenuItem>{intl.formatMessage({ id: 'submenu' })} 1 </MenuItem>
            <MenuItem>{intl.formatMessage({ id: 'submenu' })} 2 </MenuItem>
            <SubMenu title={`${intl.formatMessage({ id: 'submenu' })} 3`}>
              <MenuItem>{intl.formatMessage({ id: 'submenu' })} 3.1 </MenuItem>
              <MenuItem>{intl.formatMessage({ id: 'submenu' })} 3.2 </MenuItem>
              <SubMenu title={`${intl.formatMessage({ id: 'submenu' })} 3.3`}>
                <MenuItem>{intl.formatMessage({ id: 'submenu' })} 3.3.1 </MenuItem>
                <MenuItem>{intl.formatMessage({ id: 'submenu' })} 3.3.2 </MenuItem>
                <MenuItem>{intl.formatMessage({ id: 'submenu' })} 3.3.3 </MenuItem>
              </SubMenu>
            </SubMenu>
          </SubMenu> */}
        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: "center" }}>
      <CollapseDiv>
      {/* <span style={{    color: '#d4c0ff',fontWeight: 'bold',fontSize: '18px', marginLeft: '12px',top: '-4px'
}}>View</span> */}
        <ToggleButton
          height={21}
          width={31}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={handleCollapsedChange}
          checked={collapsed}
          onColor="#894ef4"
          offColor="#5f5f5f"
        />
        
      </CollapseDiv>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: "20px 24px",
          }}
        >
          <div    className="sidebar-btn">
          <Link to={'//github.com/rajotron'} target={'_blank'} rel="noopener noreferrer"  className="no_decor">
            <div className="icon">
              <i className="fa fa-github"></i>
            </div>
            </Link>
            <Link to={'contact'} className="no_decor">
            <div className="icon">
            <img style={{height:'50px'}} src={CommunicateIcon} alt={'communicate'} />
            </div></Link></div>
        
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
