import { Snackbar } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import WriteToUsIcon from "../../assets/feather.svg";
import { Footer } from "../layouts/footer/footer";
import { Social } from "../topBar/socialLinks";

function Copyright() {
  return (
    <Typography variant="body2" style={{ color:"#9dbecd"}} align="center">
      {"Copyright © "}
      <a style={{ color:"#fafafa"}} href="https://rajatsehgal.com/" target={'_blank'} rel="noopener noreferrer">
        Rajat Sehgal
      </a>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    fontFamily: "poiret",
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    height:80,
    width:80,
    backgroundColor: "#f1bace52",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
interface ResponseMailSend {
  message:string,
  status:string
} 
export const Contact = ()=> {
  const classes = useStyles();
 
  const [response, setResponse] = useState<ResponseMailSend | undefined>(undefined);


  const onSubmit = async (e:any) => {
    e.preventDefault();
    const { firstName,lastName, email, message } = e.target.elements;
    let details = {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      message: message.value,
    };
    console.log('Details - ',details)
    let response = await fetch(`/.netlify/functions/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    let result = await response.json();
    setResponse(result);
    setOpen(true);
    //alert(result.result);
  };
  const [open, setOpen] = React.useState(false);

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const MainDiv = styled.div`
height: 100%;
margin-bottom: 54px;
padding: 25px;
overflow: hidden overlay;
@media screen and (max-width: 800px) {
  padding:5px;
  padding-right: 15px !important;
}
`
const CustomDiv = styled.div`
background: linear-gradient(
    117deg
    , rgb(197, 159, 173) 0%, rgba(224, 177, 209, 0.43) 53%, rgba(168, 124, 253, 0.37) 100%);
        border-radius: 20px;
        padding: 22px;
        margin-left: 16%;
    margin-right: 16%;
    @media screen and (max-width: 800px) {
        margin-left: 4%;
    margin-right: 4%;
      }
`

  return (<>
    {
      response && ( <Snackbar open={open} autoHideDuration={10000} onClose={handleSnackbarClose}>
<Alert onClose={handleSnackbarClose} severity={response.status==='error' ? "error" : "success"}>
<AlertTitle>{response.status==='error' ? "Error" : "Success"}</AlertTitle>
{response.message}
  </Alert></Snackbar>

      )
    }<MainDiv>
        <div style={{textAlign:'center', margin:20}}><Social /></div>
   <CustomDiv>
      <CssBaseline />
      <div style={{fontSize: "35px", textAlign:'center' }}>
        Thanks for being here 😊
      </div>
      <div style={{marginTop: '31px',padding: '25px'}}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <img src={WriteToUsIcon} style={{height:50, width:'auto'}} alt="write to us" />
        </Avatar>
        <Typography component="h1" variant="h5" style={{fontWeight:'bold'}}>
          Write to us
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-multiline-static"
                label="Message"
                multiline
                fullWidth
                name="message"
                rows={6}
                defaultValue=""
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
             style={{backgroundColor:'var(--color-text-2)'}}
            
            className={classes.submit}
           
          >
            Send
          </Button>
          <Link to='/' style={{textDecoration: 'none'}}> 
        <Button
            fullWidth
            variant="contained"
            color="primary"
             style={{backgroundColor:'var(--color-text-2)'}}
            
            className={classes.submit}
          >
            Back to Home
          </Button>
        </Link>
        </form>
      
      </div>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box></CustomDiv>
      <Footer />
      </MainDiv>
    </>
  );
}
