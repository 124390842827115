import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import { Landing } from './components/landing/landing';
import Layout from './components/layouts/main-layout/layout';
import './styles/layout.scss';
import { GlobalStyles } from './styles/variables';

function App() {
  // const history = createBrowserHistory()
  const [locale, setLocale] = useState<any>('en');
  return (
    <>
    <Router>
    <GlobalStyles />
    {/* <Navbar />
    <Landing /> */}
  
    <Switch>
      <Route path={'/'} exact={true} ><Landing/></Route>
      <Route path={'/profile'} >
      <IntlProvider locale={locale}>
      <Layout setLocale={setLocale} />
    </IntlProvider>
    </Route> 
      </Switch>
    {/* <IntlProvider locale={locale}>
      <Layout setLocale={setLocale} />
    </IntlProvider> */}
    </Router>
  
    </>
  );
}

export default App;
