import React, { useState } from 'react';
import { useParams } from 'react-router';
import { MainLayout } from '../main-layout';
import Aside from './aside';
function Layout({ setLocale }) {
  // const [rtl, setRtl] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  // const [image, setImage] = useState(true);
  const [toggled, setToggled] = useState(false);

  const {type} = useParams()

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  // const handleRtlChange = (checked) => {
  //   setRtl(checked);
  //   setLocale(checked ? 'ar' : 'en');
  // };
  // const handleImageChange = (checked) => {
  //   setImage(checked);
  // };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <div className={`app ${toggled ? 'toggled' : ''}`}>
     
      <Aside
       
        collapsed={collapsed}
       
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      />

      <MainLayout type={type} handleToggleSidebar={handleToggleSidebar}/>
      
    </div>
  );
}

export default Layout;
