

import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

export const MainFooter = ()=>{

    const CustomDiv = styled.div`
    text-align: center;
    font-size: 40px;
    /* margin: 113px; */
    position: fixed;
    bottom: 3px;
    right: 11px;
    z-index: 100000;
    `

    return (<><CustomDiv>
  <Typography variant="body2" style={{ color:"#9dbecd"}} align="center" className={'gradientText3'}>
      {"Copyright © "}
      <a href="https://rajatsehgal.com/" target={'_blank'} rel="noopener noreferrer">
        Rajat Sehgal
      </a>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography></CustomDiv>
    </>)
}