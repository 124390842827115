import React from 'react';
import styled from 'styled-components';
import insta from '../../assets/insta.svg';
import mail from '../../assets/mail.svg';
import phone from '../../assets/phone.png';
import skype from '../../assets/skype.svg';
import whatsapp from '../../assets/whatsapp.svg';

export const Social = ()=>{


    const CustomDiv = styled.div`
    color: white;
    z-index: 10000;
    `
const CustomImg = styled.img`
height: 38px;
    width: auto;
    margin-left: 19px;
    @media screen and (max-width: 800px) {
        height: 33px;
        margin-left: 24px;
        left: -16px;
        position: relative;
    }
    }
`
const CustomLink = styled.a`
text-decoration:none;

`

const list = [{
    src:phone,link:'tel:+918474955248'
},
{
    src:whatsapp,link:'//wa.me/918474955248'
},
{
    src:mail,link:'mailto:rajatddjsehgal@gmail.com'
},
{
    src:skype,link:'skype:rajatddjsehgal?call'
},
{
    src:insta,link:'//www.instagram.com/rajotron/'
}]
    return (<>
    <CustomDiv>
      {list.map(x=>{
          return( <CustomLink href={x.link} target={'_blank'} rel="noopener noreferrer">
          <CustomImg src={x.src}/>
          </CustomLink>)
      })  
        }
    
    </CustomDiv>
    </>)
}