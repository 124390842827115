

import React from 'react';
import styled from 'styled-components';

export const Footer = ()=>{

    const CustomDiv = styled.div`
   text-align:center;
   font-size: 40px;
    margin: 113px;
    @media screen and (max-width: 800px) {
        font-size: 26px;
    margin: 53px;}
    `

    return (<>
    <CustomDiv className={'gradientText2'}>
    I occasionally take on freelance opportunities.
    </CustomDiv>
    </>)
}