import React from "react";
import styled from "styled-components";
// ------------- Icons -------------
import pgh from "../../assets/pgh.png";
import cfh from "../../assets/cfh.png";
import mjc from "../../assets/mjc.png";
import medium from "../../assets/medium.png";
import devass from "../../assets/devass.png";
import { Footer } from "../layouts/footer/footer";

// ------------- Icons -------------

export const Projects = () => {

  const MainDiv = styled.div`
  height: 100%;
  margin-bottom: 54px;
  padding: 25px;
  overflow: hidden overlay;
  @media screen and (max-width: 800px) {
    padding:5px;
    padding-right: 15px !important;
  }
  `
  const ItemGrid = styled.div`
  display: grid;
  grid-gap: 6rem;
  grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
  `

  const ItemDiv = styled.div`
  text-align:center;
  display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-content: space-between;
    border-radius: 26px;
    box-shadow: inset 13px 13px 25px -17px #ffffffb5;
    -webkit-box-shadow:  inset 13px 13px 25px -17px #ffffffb5;
-moz-box-shadow:  inset 13px 13px 25px -17px #ffffffb5;
`

  const Title = styled.div`
  text-align:center;
  font-size:30px;
  margin-top:30px;
  color:white;

  `
  const Content = styled.div`
  text-align:center;
  font-size: 18px;
    line-height: 30px;
    color: var(--color-normal-text);
  `
  const IconDiv = styled.div`
  text-align:center;
  `
const CustomImg = styled.img`
text-align:center;
height:230px;
width:auto;
`

const FooterDiv = styled.div`
text-align:center;
font-size:18px;
color:white;
margin-top: 50px;
@media screen and (max-width: 800px) {
  margin-top: 30px;

}
`

const Header = styled.div`
display:flex;
flex-direction: column;
margin-bottom:30px;

`

const itemsList = [{
  imageSrc:pgh, title:'pgHENCHMAN',content:'pgHENCHMAN is an Open Source Client for POSTGRESQL for administration and development which includes intuitive GUI for easy usage and ad-hoc analysis on the postgres database.',linkLabel:'go to website',link:'https://pghenchman.netlify.app'
},
{
  imageSrc:cfh, title:'CSV File Handler',content:'Convert your csv files into structured json and more info about your files.',linkLabel:'go to website', link:'https://csv-file-handler.netlify.app/'
},
{
  imageSrc:mjc, title:'Moment.js Converter',content:`Now you can generate code for your application for all momentjs features, your date formats problem's solution at one place`,linkLabel:'in progress', link:''
},
{
  imageSrc:medium, title:'Blogging',content:'Blogs on topics like React VR, CORS, Configuration of angular applications and step by step guide to create your first complete react application with express and mongoDB.',linkLabel:'checkout the blogs', link:'https://medium.com/@rajat_sehgal'
},
{
  imageSrc:devass, title:'AWS Certified - Developer Associate',content:'Blogs on topics like React VR, CORS, Configuration of angular applications and step by step guide to create your first complete react application with express and mongoDB.',linkLabel:'See the credentials here', link:'https://drive.google.com/file/d/1JTEw4wxNJc-f_jDe_gK3HGNUtMpOztzi/view'
}] 

  return (
    <>
     
        <MainDiv>
          <ItemGrid className={'some-area'}>
        {itemsList.map(x=>{
          return ( <ItemDiv>
            <Header>          <IconDiv><CustomImg alt="" src={x.imageSrc}/></IconDiv>
                      <Title>{x.title}</Title>
                    </Header>  <Content>{x.content}</Content>
                  <FooterDiv>{x.link.length === 0 ? <span style={{color:'var(--color-normal-text-2)'}}>{x.linkLabel}</span> : <a href={x.link} target="_blank"  rel="noopener noreferrer" style={{color:'var(--color-normal-text-2)', cursor:'pointer'}}> {x.linkLabel}</a> }</FooterDiv>
                    </ItemDiv>)
        }) }
      
          </ItemGrid>
          <Footer />
        </MainDiv>
      
    </>
  );
};
