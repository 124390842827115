import Divider from "@material-ui/core/Divider";
import React from "react";
import styled from "styled-components";
import Asts from "../../assets/asts.png";
import Lumiq from "../../assets/lumiq.png";
// ------------- Icons -------------
import aws from "../../assets/skills/aws.svg";
import blockchain from "../../assets/skills/blockchain.svg";
import docker from "../../assets/skills/docker.svg";
import Git from "../../assets/skills/git.svg";
import html from "../../assets/skills/html.svg";
import javascript from "../../assets/skills/javascript.svg";
import jira from "../../assets/skills/jira.svg";
import mongo from "../../assets/skills/mongo.svg";
import nodejs from "../../assets/skills/node-js.svg";
import postgres from "../../assets/skills/postgres.png";
import python from "../../assets/skills/python.svg";
import redux from "../../assets/skills/redux.svg";
import sequelize from "../../assets/skills/sequelize.svg";
import typescript from "../../assets/skills/typescript.svg";
import nest from "../../assets/skills/nest1.svg";
import react from "../../assets/skills/react.svg";
import angular from "../../assets/skills/angular.svg";
import { ListOfItemsForASTS } from './astsWorkItems';
import { ListOfItemsForLumiq } from './lumiqWorkItems';
import { Link } from "react-router-dom";
import { Footer } from "../layouts/footer/footer";

// ------------- Icons -------------


export const Work = () => {
  const HeaderDiv = styled.div`
    color: white;
    display: flex;
    justify-content: end;
    font-size: 24px;
  `;

  const NumDiv = styled.div`
    font-size: 139px;
    font-family: "Roboto";
    color: #80808070;
    margin-right: 20px;
    @media screen and (max-width: 800px) {
      font-size: 105px;
      margin-right: 5px;
    }

  `;

  const NameDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `;

  const CustomImg = styled.img`
    height: 80px;
    width:auto;
    @media screen and (max-width: 800px) {
      height: 23px;
    }
  `;

  const TitleDiv = styled.div`
  font-weight:bold;
  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
  `

  const SubHeaderDiv = styled.div`
    font-size: 18px;
    margin-top: 10px;
    @media screen and (max-width: 800px) {
      font-size: 13px;
    }

  `;

  const ContentDiv = styled.div`
    display: flex;
    color: #ffffffb8;
    justify-content: center;
  `;

  
  const MainDiv1 = styled.div`
  height: 100%;
    overflow: overlay;
    @media screen and (max-width: 800px) {
      padding:5px;
      padding-right: 15px !important;
    }
  
  `

  const MainDiv = styled.div`
    overflow: hidden overlay;
    border-radius: 24px;
    margin-top: 10px;
    margin-right: 22px;
    margin-left: 12px;
    margin-bottom: 50px;
    @media screen and (max-width: 800px) {
      margin-right: 5px;
    margin-left: 5px;
    }
  `;

  const FooterDiv = styled.div`
  color: var(--color-normal-text);
    padding:6px;
    display: flex;
    justify-content: space-around;
  `;

  const SkillTags = styled.div`
    display: flex;
    background: #ffffff0d;
    border-radius: 30px;
    padding: 2px 10px 2px 10px;
    margin-right: 15px;
    margin-bottom: 10px;
  `;
  const SkillLabel = styled.span`
    display: flex;
    font-size:12px;
    flex-direction: column;
    justify-content: center;
  `;

  const AstsSkills = [
    { src: nest, name: "NestJs" },
    { src: react, name: "React" },
    { src: redux, name: "Redux" },
    { src: nodejs, name: "NodeJs" },
    { src: typescript, name: "Typescript" },
    { src: sequelize, name: "Sequelize" },
    { src: javascript, name: "Javascript" },
    { src: html, name: "Html" },
    { src: postgres, name: "Postgres" },
    { src: jira, name: "Jira" },
    { src: Git, name: "Git" },
    { src: docker, name: "Docker" },
    { src: aws, name: "Aws" },

  ];

  const LumiqSkills = [
    { src: nest, name: "NestJs" },
    { src: nodejs, name: "NodeJs" },
    { src: javascript, name: "Javascript" },
    { src: angular, name: "Angular" },
    { src: typescript, name: "Typescript" },
    { src: sequelize, name: "Sequelize" },
    { src: mongo, name: "mongoDB" },
    { src: python, name: "python" },
    { src: blockchain, name: "blockchain" },
    
    { src: html, name: "Html" },
    { src: postgres, name: "Postgres" },
    { src: Git, name: "Git" },
    { src: docker, name: "Docker" },
    { src: aws, name: "Aws" },

  ];

  return (
    <>
      <MainDiv1>
        {/* -------------    ASTS      -------------- */}
        <MainDiv
          style={{
            background:
              "linear-gradient(90deg, rgba(159, 94, 117, 0.17) 0%, rgb(67 31 44 / 64%) 53%, rgba(22, 16, 0, 0.43) 100%)",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between", marginTop:20 }}>
            <HeaderDiv>
              <NumDiv>1</NumDiv>
              <NameDiv>
                <TitleDiv>ASTS TECHNOLOGY</TitleDiv>
                <SubHeaderDiv>Australia (Remote)</SubHeaderDiv>
                <SubHeaderDiv>2020 - Present</SubHeaderDiv>
                <Link to={'//asts.technology/'} target={'_blank'} rel="noopener noreferrer"  className="no_decor customLink">Go to website</Link>
              </NameDiv>
            </HeaderDiv>
            <NameDiv>
              <div>
                <CustomImg src={Asts} />
              </div>
            </NameDiv>
          </div>
          <ContentDiv>
            <ListOfItemsForASTS />
          </ContentDiv>
          <Divider />
          <FooterDiv>
            <SkillLabel style={{fontSize:16}}>Skills</SkillLabel>
            <div style={{display: 'flex',width: '90%',justifyContent: 'center', flexWrap: 'wrap',flexFlow: 'wrap', lineHeight: '31px' }}>
              {AstsSkills.map((x) => {
                return (
                  <SkillTags>
                    {" "}
                    <SkillLabel>  <img src={x.src} alt="" style={{ height: '23px',marginRight: '7px' }} /></SkillLabel>
                    <SkillLabel>{x.name}</SkillLabel>
                  </SkillTags>
                );
              })}
            </div>
          </FooterDiv>
        </MainDiv>

        {/* -------------    Lumiq      -------------- */}

        <MainDiv
          style={{
            background:
              "linear-gradient(90deg, rgba(0, 85, 144, 0.25) 0%, rgb(16 28 26 / 76%) 53%, rgba(0, 22, 19, 0.43) 100%)",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <HeaderDiv>
              <NumDiv>2</NumDiv>
              <NameDiv>
                <TitleDiv>LUMIQ</TitleDiv>
                <SubHeaderDiv>Noida, India</SubHeaderDiv>
                <SubHeaderDiv>2018 - 2020</SubHeaderDiv>
                <Link to={'//lumiq.ai/'} target={'_blank'} rel="noopener noreferrer"  className="no_decor customLink">Go to website</Link>
              </NameDiv>
            </HeaderDiv>
            <NameDiv>
              <div>
                <CustomImg src={Lumiq} />
              </div>
            </NameDiv>
          </div>
          <ContentDiv>
            <ListOfItemsForLumiq />
          </ContentDiv>
          <Divider />
          <FooterDiv>
            <SkillLabel style={{fontSize:16}}>Skills</SkillLabel>
            <div style={{display: 'flex',width: '90%',justifyContent: 'center', flexWrap: 'wrap',flexFlow: 'wrap', lineHeight: '31px' }}>
              {LumiqSkills.map((x) => {
                return (
                  <SkillTags>
                    {" "}
                    <SkillLabel>  <img src={x.src} alt="" style={{ height: '23px',marginRight: '7px' }} /></SkillLabel>
                    <SkillLabel>{x.name}</SkillLabel>
                  </SkillTags>
                );
              })}
            </div>
          </FooterDiv>
        </MainDiv>
        <Footer />
      </MainDiv1>
    </>
  );
};
