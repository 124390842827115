import React from 'react';
import styled from 'styled-components';
import { Social } from './socialLinks';

export const TopBar = ()=>{

    const CustomDiv = styled.div`
    color: white;
    z-index: 10000;
    position: fixed;
    top: 18px;
    right: 21px;
    @media screen and (max-width: 800px) {

        top: 3px;
        right: 3px;
    }
    `

    return (<>
    <CustomDiv>

        <Social/>
    </CustomDiv>
    </>)
}