import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './landing.scss';
import owner from "../../assets/owner_1.png";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button, Tooltip } from '@material-ui/core';
import react from "../../assets/skills/react.svg";
import heart from "../../assets/skills/heart.png";
import styled from 'styled-components'
import { TopBar } from '../topBar/topbar';

// import './animation_landing.js'
export const Landing = () =>{
   

   const ContainerOnMouseMove = (e:any)=>{
   

    let xAxis = (window.innerWidth / 2 - e.pageX) / 25;
    let yAxis = (window.innerHeight / 2 - e.pageY) / 25;
    setCardTransform(`rotateY(${xAxis}deg) rotateX(${yAxis}deg)`)
    }
    const [cardTransform,setCardTransform ] = useState('')
    const [TitleTransform,setTitleTransform ] = useState('')
    const [SneakerTransform,setSneakerTransform ] = useState('')
    const [DescriptionTransform,setDescriptionTransform ] = useState('')
    const [purchaseTransform,setpurchaseTransform ] = useState('')
    const [sneakerBorderRadius,setSneakerBorderRadius ] = useState('')
    const [FooterTransform,setFooterTransform ] = useState('')
    

    const ContainerOnMouseEnter = (e:any)=>{
       setCardTransform("none")
        //Popout
       setTitleTransform("translateZ(100px)")
        setSneakerTransform("translateZ(130px)")
       setDescriptionTransform("translateZ(105px)")
      setpurchaseTransform("translateZ(150px)")
      setSneakerBorderRadius("50%")
      setFooterTransform("translateZ(70px)")
      }
      const ContainerOnMouseLeave = (e:any)=>{
       setCardTransform("all 0.5s ease")
       setCardTransform(`rotateY(0deg) rotateX(0deg)`)
        //Popback
       setTitleTransform("translateZ(0px)")
        setSneakerTransform("translateZ(0px) rotateZ(0deg)")
       setDescriptionTransform("translateZ(0px)")
      setpurchaseTransform("translateZ(0px)")
      setFooterTransform("translateZ(20px)")
    //   setSneakerBorderRadius("0")
      }
const CustomImg = styled.img`
height:25px;
width: auto;
top:5px;
position:relative;
@media only screen and (max-width: 800px) {
height:15px;
top:2px;
}
`

return(
    <>  <TopBar/>
    <div className={'parentContainer'}>
     <div className="container1" onMouseMove={(e)=>ContainerOnMouseMove(e)} onMouseEnter={(e)=>ContainerOnMouseEnter(e)}  onMouseLeave={(e)=>ContainerOnMouseLeave(e)} >
        <div className="card1" style={{transform:cardTransform}}>
            <div className="sneaker" >
                <div className="circle"></div>
                <img src={owner} alt="adidas" style={{transform:SneakerTransform, borderRadius:sneakerBorderRadius}}/>
            </div>
            <div className="info">
                <h1 className="title" style={{transform:TitleTransform}}>RAJAT SEHGAL</h1>
               
                <h3 style={{transform:DescriptionTransform}}>NOT ONLY A <br/> FULL STACK DEVELOPER</h3>
                {/* <div className="sizes" style={{transform:sizesransform}}>
                    <button>39</button>
                    <button>40</button>
                    <button className="active">42</button>
                    <button>44</button>
                </div> */}
                <div className="purchase" style={{transform:purchaseTransform}}>
                   <Link to={'/profile/aboutme'} style={{textDecoration:'none'}}>
                   <Button endIcon={<ArrowForwardIcon/>} style={{color: 'black'}}>Let's begin </Button>
                   </Link>
                    
                </div>
            </div>
            <div className="footer gradientText1"  style={{transform:FooterTransform}}>
I have built this website with  <Tooltip title="Heart" placement="top"><CustomImg src={heart} /></Tooltip> and  <Tooltip title="React" placement="top"><CustomImg  src={react} /></Tooltip>
            </div>
        </div>
    </div>
    </div>
    </>
)
}
